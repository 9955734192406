import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
// import ContentServices from "../components/Services/ContentServices";
import BaseLayout from "../components/global/BaseLayout";
import ServicesHome from "../components/Home/ContentServicesHome";
import CounterUp from "../components/global/CounterUp";
import Carrusel from "../components/global/Carrusel";
import BlockSecondary from "../components/block/Block_1";



function Services() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Ministerios">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Ministerios"
          Subheader="Ministerios"
          bgimg={`${rpdata?.gallery?.[15]}`}
        />
        {/* <ContentServices/> */}
        <ServicesHome />
        <CounterUp image={rpdata?.gallery?.[22]} />
        <Carrusel />
        <BlockSecondary
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.gallery?.[10]}
        />
      </div>
    </BaseLayout>
  );
}

export default Services;
