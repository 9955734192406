import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";


const Carrusel = () => {

  const { rpdata } = useContext(GlobalDataContext)

  return (
    <section className='py-5'>
      <h2 className='capitalize text-center py-5'>nuestros próximos eventos</h2>
      <div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          modules={[Autoplay, Pagination]}
        >
          {
            rpdata?.stock?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="h-[350px] w-full">
                    <img
                      src={item}
                      alt='images'
                      className="w-full h-full object-contain"
                    />
                  </div>
                </SwiperSlide>

              )
            })
          }

        </Swiper>
      </div>
    </section>
  )
}

export default Carrusel